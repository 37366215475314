import { createRef } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { SearchInput } from '@nerdwallet/search-components';
import config from '@nerdwallet/app-config';
import { getLogger } from '@nerdwallet/logging';
import { useStore } from '@nanostores/preact';

import ApolloWrapper from '../auth/global/ApolloWrapper';
import MagnifyingGlass from '../auth/svg/magnifying-glass';
import CloseButton from '../auth/buttons/CloseButton';
import ErrorBoundary from '../lib/ErrorBoundary';
import {
  $openedMenu,
  $onCloseMenuCallback,
  $rightButtonsReady,
  $searchLoaded,
} from '../store/client';

import styles from './Search.module.less';
import useAnalytics from '../lib/segment/useAnalytics';
import { AnalyticsProvider } from '@nerdwallet/seo-analytics';

interface Props {
  includeSearch?: boolean;
}

const Search: React.FC<Props> = ({ includeSearch = true }) => {
  const logger = getLogger('right-buttons');
  logger.info('Search island is loading...');

  const analytics = useAnalytics();

  const [checkboxInputChecked, setCheckboxInputChecked] = useState(false);
  const checkboxInputRef = createRef();
  const rightButtonsReady = useStore($rightButtonsReady);

  // Sets $searchLoaded when this component is ready to render client-side
  useEffect(() => {
    $searchLoaded.set(true);
  }, []);

  const trackInteraction = (entity: string, itemCopy: string) => {
    return analytics?.track('feature_interacted', {
      entity_name: entity,
      section_name: 'onsite_search',
      item_copy: itemCopy,
      interaction_type: 'clicked',
    });
  };

  const trackSearchOpen = (event) => {
    if (!event.target.checked) {
      return;
    }

    return trackInteraction(
      'onsite_search_magnifying_glass_icon',
      'magnifying_glass_icon'
    );
  };

  const onCheckboxClick = async (event) => {
    try {
      await trackSearchOpen(event);
      checkboxInputRef.current.checked = true;
      setCheckboxInputChecked(checkboxInputRef.current.checked);
      $openedMenu.set('search');
    } catch (err) {
      logger.debug('Search click is not working', err);
    }
  };

  const closeButtonClick = () => {
    const checkbox = checkboxInputRef.current;
    if (!checkbox) return;
    checkbox.checked = false;
    setCheckboxInputChecked(false);
    $openedMenu.set('');
  };

  useEffect(() => {
    $onCloseMenuCallback.set(closeButtonClick);

    return () => {
      $onCloseMenuCallback.set(null);
    };
  }, [closeButtonClick]);

  if (!includeSearch || !rightButtonsReady) {
    return null;
  }

  return (
    <ErrorBoundary>
      <ApolloWrapper>
        <AnalyticsProvider>
          <div className={styles.searchWrapper}>
            <input
              type="checkbox"
              className={styles.searchButtonCheckbox}
              id="searchButtonCheckbox"
              name="searchButtonCheckbox"
              onClick={(event) => onCheckboxClick(event)}
              ref={checkboxInputRef}
            />
            <label
              for="searchButtonCheckbox"
              className={styles.searchButton}
              aria-label="Search"
            >
              <MagnifyingGlass width={20} height={20} />
            </label>
            {checkboxInputChecked ? (
              <div className={styles.searchDropdown}>
                <div className={styles.searchFormContainer}>
                  <SearchInput
                    className={styles.searchInput}
                    searchBaseUri={
                      config.SITE_BASE_URL || 'https://www.nerdwallet.com'
                    }
                  />
                </div>
                <CloseButton onClick={closeButtonClick} />
              </div>
            ) : (
              checkboxInputRef?.current
            )}
          </div>
        </AnalyticsProvider>
      </ApolloWrapper>
    </ErrorBoundary>
  );
};

export default Search;
