import AnalyticsProvider from '../lib/segment/AnalyticsProvider';
import Search from './Search';

interface Props {
  /**
   * Boolean value which will make Search component rendered if true.
   */
  includeSearch: boolean;
}

const SearchWrapper: React.FC<Props> = (props) => {
  return (
    <AnalyticsProvider>
      <Search {...props} />
    </AnalyticsProvider>
  );
};

export default SearchWrapper;
