interface Props {
  className?: string;
  width?: number;
  height?: number;
}

const MagnifyingGlass: React.FC<Props> = ({ className = '', ...props }) => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="1518 257.8 100 100"
    {...props}
  >
    <path
      fill="currentColor"
      d="M1591.5 324.2c5.8-7.5 8.7-15.9 8.7-25.3 0-5.5-1-10.7-3.1-15.8-2.1-5-5.1-9.5-9-13.3-3.9-3.9-8.3-6.8-13.3-8.9s-10.3-3.1-15.8-3.1-10.7 1-15.7 3.1-9.5 5-13.4 8.9c-3.9 3.9-6.8 8.3-8.9 13.3-2.1 5-3.1 10.3-3.1 15.8s1 10.7 3.1 15.8c2.1 5 5 9.5 8.9 13.3 3.9 3.9 8.4 6.8 13.4 8.9s10.2 3.1 15.7 3.1c9.4 0 17.9-2.9 25.3-8.7l26.5 26.5 7.1-7.1-26.4-26.5zm-63.5-25.3c0-8.6 3-15.9 9.1-22 6-6 13.4-9.1 22-9.1s15.9 3 22 9.1c6 6.1 9.1 13.5 9.1 22 0 8.6-3 15.9-9.1 22s-13.4 9.1-22 9.1-15.9-3-22-9.1-9.1-13.4-9.1-22z"
    />
  </svg>
);

export default MagnifyingGlass;
